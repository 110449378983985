// Generated by CoffeeScript 1.9.2
(function() {
  (function($, d, w) {
    var PostCounter;
    PostCounter = (function() {
      var self;

      self = false;

      function PostCounter() {
        self = this;
        self.countTimeout = 1000 * 60;
      }

      PostCounter.prototype.isRunnable = function() {
        return $('#track-post-counter').length > 0;
      };

      PostCounter.prototype.ready = function() {
        self = this;
        if (self.isRunnable()) {
          return self.trackPost();
        }
      };

      PostCounter.prototype.load = function() {};

      PostCounter.prototype.trackPost = function() {
        var dataSend, postId, type, url, userId;
        userId = $('#track-post-counter').data("user");
        type = $('#track-post-counter').data("type");
        postId = $('#track-post-counter').data("post");
        url = $('#track-post-counter').data("url");
        dataSend = {
          'user_id': userId,
          'type': type,
          'post_id': postId,
          'action': 'save_post_count',
          'is_ajax': 1
        };
        return w.setTimeout(function() {
          $.ajax({
            type: 'POST',
            url: url,
            dataType: "json",
            data: dataSend,
            success: function(data) {
              console.debug(data.message);
            }
          });
        }, self.countTimeout);
      };

      return PostCounter;

    })();
    w.postCounter = new PostCounter();
    postCounter.ready();
  })(jQuery, document, window);

}).call(this);
